export const colors = {
  background: '#020018',
  primary: {
    white: '#FFFFFF',
    dark: '#020018',
    blue: '#012BF6',
    purple: '#660CF6',
    gold: '#FFA439',
    green: '#2BBF8A',
    red: '#F43F3F',
  },
  purple: {
    dark: {
      100: '#5C0BDD',
      200: '#530AC7',
      300: '#4A09B3',
      400: '#4308A1',
      500: '#3C0791',
      600: '#360683',
      700: '#310676',
      800: '#280561',
      900: '#20044E',
      1000: '#15033E',
    },
    light: {
      100: '#700DFF',
      200: '#7B0FFF',
      300: '#8810FF',
      400: '#9512FF',
      500: '#A413FF',
      600: '#B515FF',
      700: '#C717FF',
      800: '#6A2F75',
    },
    gradients: {
      4: 'linear-gradient(48.09deg, #c717ff 0%, #3c0791 62.24%)',
    },
  },
  gold: {
    dark: {
      100: '#E59433',
      200: '#CF852E',
      300: '#BA782A',
      400: '#A76C25',
      500: '#976122',
      600: '#7D501C',
      700: '#684317',
    },
    light: {
      50: '#FFA439',
      100: '#FFB43F',
      200: '#FFC645',
      300: '#FFDA4C',
      400: '#FFF053',
      500: '#FFFF5C',
      600: '#FFFF75',
      700: '#FFFF8F',
    },
  },
  blue: {
    dark: {
      100: '#0127DD',
      200: '#0123C7',
      300: '#011FB3',
      400: '#011CA1',
      500: '#011991',
      600: '#011783',
      700: '#001576',
    },
    light: {
      100: '#012FFF',
      200: '#0134FF',
      300: '#0139FF',
      400: '#013FFF',
      500: '#024CFF',
      600: '#1460FF',
      700: '#0265FF',
    },
  },
  green: {
    dark: {
      100: '#27AC7C',
      200: '#239B70',
      300: '#1F8B65',
      400: '#1C7D5B',
      500: '#197151',
      600: '#176649',
      700: '#155B42',
    },
    light: {
      100: '#2FD298',
      200: '#34E7A7',
      300: '#39FEB8',
      400: '#3FFFCA',
      500: '#45FFDE',
      600: '#4CFFF4',
      700: '#54FFFF',
    },
  },
  red: {
    dark: {
      100: '#DC3939',
      200: '#C63333',
      300: '#B22E2E',
      400: '#A02929',
      500: '#902525',
      600: '#822121',
      700: '#751E1E',
    },
    light: {
      100: '#FF4545',
      200: '#FF4C4C',
      300: '#FF5454',
      400: '#FF5C5C',
      500: '#FF6565',
      600: '#FF7070',
      700: '#FF7B7B',
    },
  },
  white: {
    dark: {
      100: '#6C6B7B',
      200: '#4E4C5D',
      300: '#353346',
      400: '#28263A',
      500: '#1B192F',
      600: '#0F0D24',
      700: '#020018',
    },
    light: {
      100: '#F3F2F5',
      200: '#E6E6EB',
      300: '#DAD9E0',
      400: '#CDCDD4',
      500: '#B3B2BD',
      600: '#9998A6',
      700: '#818190',
    },
  },
  shades: {
    0: '#FFFFFF',
    10: '#F3F2F5',
    20: '#E6E6EB',
    30: '#DAD9E0',
    40: '#CDCDD4',
    50: '#B3B2BD',
    60: '#9998A6',
    70: '#818190',
    80: '#6C6B7B',
    90: '#4E4C5D',
    100: '#353346',
    110: '#28263A',
    120: '#1B192F',
    130: '#0F0D24',
    140: '#020018',
  },
};
