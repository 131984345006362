exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-friend-js": () => import("./../../../src/pages/add-friend.js" /* webpackChunkName: "component---src-pages-add-friend-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-circl-invite-js": () => import("./../../../src/pages/circl-invite.js" /* webpackChunkName: "component---src-pages-circl-invite-js" */),
  "component---src-pages-crowdcube-js": () => import("./../../../src/pages/crowdcube.js" /* webpackChunkName: "component---src-pages-crowdcube-js" */),
  "component---src-pages-deep-link-js": () => import("./../../../src/pages/deep-link.js" /* webpackChunkName: "component---src-pages-deep-link-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-layout-footer-js": () => import("./../../../src/pages/layout/footer.js" /* webpackChunkName: "component---src-pages-layout-footer-js" */),
  "component---src-pages-layout-header-js": () => import("./../../../src/pages/layout/header.js" /* webpackChunkName: "component---src-pages-layout-header-js" */),
  "component---src-pages-live-chat-js": () => import("./../../../src/pages/live-chat.js" /* webpackChunkName: "component---src-pages-live-chat-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refer-a-friend-terms-js": () => import("./../../../src/pages/refer-a-friend-terms.js" /* webpackChunkName: "component---src-pages-refer-a-friend-terms-js" */),
  "component---src-pages-referral-terms-js": () => import("./../../../src/pages/referral-terms.js" /* webpackChunkName: "component---src-pages-referral-terms-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-responsible-gambling-js": () => import("./../../../src/pages/responsible-gambling.js" /* webpackChunkName: "component---src-pages-responsible-gambling-js" */),
  "component---src-pages-rules-of-play-js": () => import("./../../../src/pages/rules-of-play.js" /* webpackChunkName: "component---src-pages-rules-of-play-js" */),
  "component---src-pages-stalybridge-celtic-js": () => import("./../../../src/pages/stalybridge-celtic.js" /* webpackChunkName: "component---src-pages-stalybridge-celtic-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-trial-circl-terms-js": () => import("./../../../src/pages/trial-circl-terms.js" /* webpackChunkName: "component---src-pages-trial-circl-terms-js" */)
}

