import React from 'react';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from '@tanstack/react-query';
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader';

import theme from './src/styles/theme';
import GlobalStyles from './src/styles/GlobalStyle';
import { queryClient } from 'services/query';

import 'react-loading-skeleton/dist/skeleton.css';

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <LiveChatLoaderProvider providerKey="xx7vu0o5" provider="intercom">
      <Intercom />
      <GlobalStyles />
      {element}
    </LiveChatLoaderProvider>
  </ThemeProvider>
);

const HeadComponents = [
  <script
    async
    defer
    src="https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js"
  ></script>,
  <script
    key="trustpilot-widget"
    src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    async
  ></script>,
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />,
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />,
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />,
  <link rel="manifest" href="/site.webmanifest" />,
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#020018" />,
  <meta name="msapplication-TileColor" content="#020018" />,
  <meta name="theme-color" content="#020018" />,

  <link rel="canonical" href="https://playcircl.com" />,
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />,
  <html lang="en" />,
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />,

  <title>Circl - Five Match Millions</title>,
  <meta name="description" content="The home of Five Match Millions" />,

  <meta name="twitter:card" content="summary" />,
  <meta name="twitter:site" content="@PlayCircl" />,
  <meta name="twitter:title" content="Circl - Five Match Millions" />,
  <meta name="twitter:description" content="The home of Five Match Millions" />,
  <meta name="twitter:creator" content="@PlayCircl" />,

  <meta
    name="twitter:image"
    content="https://playcircl.com/og_image_new.png"
  />,
  <meta property="og:title" content="Circl - Five Match Millions" />,
  <meta property="og:type" content="website" />,
  <meta property="og:url" content="https://playcircl.com" />,
  <meta property="og:site_name" content="Circl - Five Match Millions" />,
  <meta
    property="og:image"
    itemProp="image"
    content="https://playcircl.com/og_image_new.png"
  />,
  <meta
    property="og:image:secure_url"
    itemProp="image"
    content="https://playcircl.com/og_image_new.png"
  />,
  <meta property="og:description" content="The home of Five Match Millions" />,
  <meta property="og:site_name" content="Circl" />,
  <meta property="fb:app_id" content="308344004450396" />,
  <meta
    name="facebook-domain-verification"
    content="c1rl0s6ly6dlrux3lyiioao9nfh1s2"
  />,
];

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents(HeadComponents);
};

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  );
};
